<template>
  <div class="dashboard-container">
    <!-- 添加或编辑类别 -->
    <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
      <!-- 表单 -->
      <el-form ref="perForm" :model="formData" :rules="rules" label-width="120px">

        <el-form-item label="用户名" prop="userName">
          <el-input v-model="formData.userName" style="width:50%" />
        </el-form-item>

        <el-form-item label="账号" prop="account">
          <el-input v-model="formData.account" style="width:50%" />
        </el-form-item>

      </el-form>
      <el-row slot="footer" type="flex" justify="center">
        <el-col :span="4">
          <el-button size="small" type="primary" @click="btnOK">
            确定
          </el-button>
          <el-button size="small" @click="btnCancel">取消</el-button>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- -------万恶分割线------- -->

    <div class="app-container" style="margin-bottom: 20px">
      <el-row>
        <el-col :span="12" align="left">

          <el-button type="primary" @click="goBack">返回</el-button>
        </el-col>
        <el-col :span="12" align="right">
          <el-button class="el-icon-edit" type="primary" @click="addAccount">添加公司子账号</el-button>
        </el-col>
      </el-row>
    </div>

    <!-- 主体内容 -->

    <div class="tab">
      <el-table :header-cell-style="{ background: '#F2F6FC', color: '#606266' }" :data="list" border style="width: 100%"
        ref="list" class="emp_table" v-if="list">

        <el-table-column align="center" prop="userName" label="用户名" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="account" label="账号" :show-overflow-tooltip="true" />
        <el-table-column align="center" prop="accountLevel" label="账号等级" width="200">
          <template slot-scope="scope">
            {{ scope.row.accountLevel | dj }}
          </template>
        </el-table-column>
        <el-table-column align="center" prop="activateStatus" label="激活状态" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{ scope.row.activateStatus == true ? '激活' : '未激活' }}
          </template>
        </el-table-column>


        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="modifyAccount(scope.row)">
              编辑
            </el-button>
            <el-button type="text" size="small" @click="cz(scope.row)">
              密码重置
            </el-button>
            <el-button type="text" size="small" @click="unboundAccount(scope.row)">
              账号解绑
            </el-button>
            <el-button type="text" size="small" @click="delAccount(scope.row)" v-if="scope.row.accountLevel !== 2">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <pagination v-show="total > 0" :total="total" :page.sync="queryParams.page" :limit.sync="queryParams.pageSize"
        @pagination="getCompanyAccounts" />

    </div>
  </div>
</template>

<script>
import {
  resetAccountPassword,
  getCompanyAccounts,
  delAccount,
  addAccount,
  modifyAccount,
  unboundAccount

} from "../../request/http";

export default {
  data() {
    return {

      title: '',
      showDialog: false,
      list: "",
      total: 0,
      queryParams: {
        page: 1,
        pageSize: 10,
      },

      dialogVisible: false, //显示添加或编辑框
      formData: {
        userName: "",
        account: ""
      },
      companyId: '',
      rules: {
        userName: [{ required: true, message: '用户名称不能为空', trigger: 'blur' }],

        account: [{ required: true, message: '账号不能为空', trigger: 'blur' },
        {
          pattern: /^1[3-9]\d{9}$/,
          message: '请输入正确的手机号码'
        }
        ],


      },


    };
  },

  created() {

    this.companyId = this.$route.query.companyId
    this.getCompanyAccounts();

  },

  mounted() { },
  methods: {

    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    async cz(row) {
      try {
        await this.$confirm('确定要重置此账号吗?')
        console.log(row);
        let { code, msg } = await resetAccountPassword({
          "accountId": row.accountId
        })

        if (code == 0) {
          this.$message({
            type: "success",
            message: "重置成功,密码为手机号后6位",
          });
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }

        this.getCompanyAccounts()

      } catch (error) {
        console.log(error);
      }
    },

    //获取列表
    async getCompanyAccounts() {
      this.queryParams.companyId = this.companyId
      let { data } = await getCompanyAccounts(this.queryParams);
      // let a= data.filter(item=>{
      //    return item.accountLevel!==2
      //  })
      //  console.log(a);
      this.list = data;
      // console.log(this.list)

      this.total = data.length

    },

    // 打开添加的窗口
    addAccount() {
      this.title = '添加公司子账号'
      this.formData.companyId = this.companyId
      this.showDialog = true
    },
    // 打开编辑类别的窗口
    modifyAccount(val) {
      // 根据获取id获取详情
      this.title = '编辑会员账号信息'

      console.log(val);
      this.formData = {
        accountId: val.accountId,
        userName: val.userName,
        account: val.account,


      }
      this.showDialog = true
    },
    // 确认
    btnOK() {
      this.$refs.perForm
        .validate()
        .then(async () => {
          if (this.formData.accountId) {
            let { msg, code } = await modifyAccount(this.formData)
            if (this.formData.accountId && code == 0) {
              this.$message.success("编辑成功");
            }
            else {
              console.log(msg);
              this.$message({
                type: "error",
                message: msg,
              });
            }




          } else {
            let { msg, code } = await addAccount(this.formData)
            if (code == 0) {
              this.$message.success("添加成功");
            } else {
              console.log(msg);
              this.$message({
                type: "error",
                message: msg,
              });
            }
          }
          this.showDialog = false
          this.getCompanyAccounts()
        })

    },
    async unboundAccount(row) {
      let id = row.accountId
      let res = await unboundAccount({ accountId: id })
      console.log(res.code);
      res.code == 0 &&
        this.$message({
          type: "success",
          message: "解绑成功",
        });
      this.getCompanyAccounts()
    },
    //弹窗取消函数
    btnCancel() {

      this.formData = {

      }
      this.$refs.perForm.resetFields()
      this.showDialog = false
    },


    async delAccount(row) {
      try {
        await this.$confirm('确定要删除该数据吗')
        console.log(row);
        let { code, msg } = await delAccount({
          "accountId": row.accountId
        })

        if (code == 0) {
          this.$message({
            type: "success",
            message: "删除成功",
          });
        } else {
          this.$message({
            type: "error",
            message: msg,
          });
        }
        const totalPage = Math.ceil((this.total - 1) / this.queryParams.pageSize)
        this.queryParams.page = this.queryParams.page > totalPage ? totalPage : this.queryParams.page
        this.queryParams.page = this.queryParams.page < 1 ? 1 : this.queryParams.page

        this.getCompanyAccounts()

      } catch (error) {
        console.log(error);
      }
      //     await this.$confirm('确定要删除该数据吗')
      //      console.log(row);
      //  let {code,msg} = await delAccount({
      //      "accountId":row.accountId
      //    })

      //     if(code == 0 ){
      //        this.$message({
      //       type: "success",
      //       message: "删除成功",
      //     });
      //   } else{
      //     this.$message({
      //       type: "error",
      //       message: msg,
      //     });
      //   }
      //     const totalPage = Math.ceil((this.total -1) / this.queryParams.pageSize)
      //       this.queryParams.page = this.queryParams.page > totalPage ? totalPage : this.queryParams.page
      //       this.queryParams.page = this.queryParams.page < 1 ? 1 : this.queryParams.page

      //     this.getCompanyAccounts()

    },
  }
}




</script>

<style scoped></style>
